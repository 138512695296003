.input_form{

    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.input_section {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.input_label_section{
    width: 208px;

}

.input_field_section{
    width: 500px;
}

select{
    width: 208px;
}

.medium input{
    width: 200px;
}

.large input{
    width: 400px;
}

.btn_section{
    margin-top: 15px;
}

.btn_section button{
    font-size: 17px;
}