body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container{
  padding-top: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.port_sec {
  margin: 10px 10px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.port {
  display: flex;
  width: 32%;
  border: 1px solid black;
  margin: 3px 3px 3px 3px;
  height: 200px;
  cursor: pointer;
}

.port h1{
  text-align: center;
  margin: 0 auto;
}

/* Center table */
table {
  margin: 0 auto;
}
/* Default Table Style */
table {
  color: #333;
  background: white;
  border: 1px solid grey;
  font-size: 12pt;
  border-collapse: collapse;
}
table thead td,
table tfoot td {
  color: black;
  background: rgba(0,0,0,.1);
}
table caption {
  padding:.5em;
}
table td,
table td {
  padding: .5em;
  border: 1px solid lightgrey;
}

.title_row{
  text-align: center;
}
/* Zebra Table Style */
[data-table-theme*=zebra] tbody tr:nth-of-type(odd) {
  background: rgba(0,0,0,.05);
}
[data-table-theme*=zebra][data-table-theme*=dark] tbody tr:nth-of-type(odd) {
  background: rgba(255,255,255,.05);
}
/* Dark Style */
[data-table-theme*=dark] {
  color: #ddd;
  background: #333;
  font-size: 12pt;
  border-collapse: collapse;
}
[data-table-theme*=dark] thead th,
[data-table-theme*=dark] tfoot th {
  color: #aaa;
  background: rgba(0255,255,255,.15);
}
[data-table-theme*=dark] caption {
  padding:.5em;
}
[data-table-theme*=dark] th,
[data-table-theme*=dark] td {
  padding: .5em;
  border: 1px solid grey;
}

.pagination {
  width: 100%;
}

.pagination ul{
  display: flex;
  align-items: center;
  justify-content: center;

}

.selected{
  font-weight: 700;
  color: black !important;
  cursor: not-allowed !important;
}

.pagination  li {
  display: block;
  margin-right: 15px;
  color: blue;
  cursor: pointer;
}

.loader {
  margin-top: 5px;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

button{
  cursor: pointer;
  font-size: 16px;
  padding: 5px 5px 5px 5px;
}


.row_closed{
  background-color: burlywood;
}


.filter_field{
  margin-right: 10px;

}
.date_select select{
  width: auto;

}

.filter_field button{
  margin-right: 20px;
  margin-top: 15px;
}

.components_booked{
  background-color: #99E099;
}

.components_partial{
  background-color: #DA9E50;
}

.components_notbooked{
  background-color: #CF5555;
}

.booking_confirmed{
  background-color: #66d188;
}

.booking_part_book{
  background-color: #f2a752;
}

.booking_no_payment{
  background-color: #f78e8c;
}

.booking_no_check{
  background-color: #cecece;
}

.ledgend{
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content:center;
  margin-bottom: 20px;
}

.ledgend .block{
  margin-right: 10px;
  padding: 10px 10px 10px 10px;

}