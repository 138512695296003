.row_closed{
    background-color: burlywood;
}


.filter_field{
    margin-right: 10px;
 
}

.filter_field button{
    margin-right: 20px;
    margin-top: 15px;
}

.components_booked{
    background-color: #99E099;
}

.components_partial{
    background-color: #DA9E50;
}

.components_notbooked{
    background-color: #CF5555;
}

.booking_confirmed{
    background-color: #66d188;
}

.booking_part_book{
    background-color: #f2a752;
}

.booking_no_payment{
    background-color: #f78e8c;
}

.booking_no_check{
    background-color: #cecece;
}

.ledgend{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom: 20px;
}

.ledgend .block{
    margin-right: 10px;
    padding: 10px 10px 10px 10px;

}

.nothing_here {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    line-height: 40px;
    font-size: 18px;

}