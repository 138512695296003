.destselector_con_options{
    margin-top: 10px;
    border: 1px solid #3333;
    height: 250px;
    overflow: auto;
    padding: 10px;
    background: white;
    position: absolute;
    z-index: 500;
    width: 300px;
}

.destselector_con_option{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    text-transform: capitalize;
    background: white;
}

.destselector_chk{
    width: 24px;
    height: 24px;
    display: block;
    border-style:solid;
    border-width: 1px;
    border-radius: 2px;
    cursor: pointer;
    border-color: #99b4ca
}

.destselector_lbl{
    margin-left: 10px;
    cursor:pointer;
    color: blue
}

.destselector_con_search{
    width: 250px;
}

.destselector_con_search input{
    width: 250px;
}

.destselector_selected_options {
    font-size: 11px;
    font-style: italic;
    text-transform: capitalize;
    width: 300px;

}

